import request from '@/http/request'
import wayCode from '@/utils/wayCode'
import channelUserIdUtil from '@/utils/channelUserId'
import config from '@/config'

/** 获取url **/
export function getRedirectUrl(token, redirect) {
  return request.request({
    url: '/cli-api/redirect',
    method: 'POST',
    data: { pay_way: wayCode.getPayWay().wayCode, token: token, redirect: redirect }
  })
}

export function oauthInfoDo(token, code) {
  return request.request({
    url: '/cli-api/authinfo',
    method: 'POST',
    data: { wayCode: wayCode.getPayWay().wayCode, token: token, code: code }
  })
}

export function oauthAlipayInfoDo(token, code) {
  return request.request({
    url: '/cli-api/alipay_authinfo',
    method: 'POST',
    data: { wayCode: wayCode.getPayWay().wayCode, token: token, code: code }
  })
}


/** 获取url **/
export function getChannelUserId(redirectData) {
  return request.request({
    url: '/api/cashier/channelUserId',
    method: 'POST',
    data: Object.assign({ wayCode: wayCode.getPayWay().wayCode, token: config.cacheToken }, redirectData)
  })
}

/** 调起支付接口, 获取支付数据包 **/
export function getPayPackage(amount, token) {
  return request.request({
    url: '/cli-api/wx_jspay',
    method: 'POST',
    data: {
      wayCode: wayCode.getPayWay().wayCode,
      token: config.cacheToken,
      amount: amount,
      channelUserId: channelUserIdUtil.getChannelUserId()
    }
  })
}


/** 支付宝调起支付接口, 获取支付数据包 **/
export function getAliPayPackage(amount, token) {
  return request.request({
    url: '/cli-api/alipay_trade',
    method: 'POST',
    data: {
      wayCode: wayCode.getPayWay().wayCode,
      token: config.cacheToken,
      amount: amount,
      channelUserId: channelUserIdUtil.getChannelUserId()
    }
  })
}

/** 调起支付接口, 获取订单信息 **/
export function getPayOrderInfo(token) {
  return request.request({
    url: '/cli-api/info',
    method: 'POST',
    data: {
      token: token
    }
  })
}

/** 取消订单 **/
export function cancelPay() {
  return request.request({
    url: '/api/cashier/cancelPay',
    method: 'POST',
    data: {
      token: config.cacheToken
    }
  })
}
